<template>
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.71751 5.7106C5.66669 5.76118 5.61155 5.80387 5.55354 5.84099L1.67287 9.71651C1.29429 10.0944 0.680078 10.0946 0.301313 9.71634C-0.0774502 9.33824 -0.0774502 8.72499 0.301314 8.34653L3.64338 5.00874L0.284073 1.65356C-0.094691 1.27546 -0.0946909 0.662391 0.284073 0.283754C0.473545 0.0943456 0.721924 4.9225e-07 0.969764 5.13917e-07C1.2176 5.35584e-07 1.46616 0.0943457 1.65545 0.283754L5.55354 4.17721C5.61155 4.21434 5.66705 4.25703 5.71751 4.30761C5.91111 4.50096 6.0045 4.75566 5.99983 5.0091C6.0045 5.26254 5.91111 5.51706 5.71751 5.7106Z"
      fill="currentColor"
    />
  </svg>
</template>
